const ImageFileWithType = (backgroundList, fileName, fileType) => {
  let image_url = 'https://va-background-images.s3.amazonaws.com/';
  if (!fileName?.includes('bg_')) {
    if (fileType != null && fileType !== '') {
      // console.log(image_url + fileName + '.' + fileType);

      return image_url + fileName;
    } else {
      if (fileName?.includes('bg_')) {
        return image_url + fileName + '.gif';
      } else {
        return image_url + fileName;
      }
    }
  } else {
    return image_url + fileName + '.gif';
  }
};

export default ImageFileWithType;
