import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef
} from 'react';
import styles from './generate.module.scss';
import { VaText, VaTitle } from '../typography';
import {
  Image,
  Input,
  Tabs,
  message,
  Spin,
  Progress,
  Button,
  Modal,
  Form,
  Popconfirm,
  Select,
  Tooltip
} from 'antd';
import Background from './background';
import Audio from './audio';
import Images from './images';
import ContentText from './contentText';
import AudioText from './audioText';
import Avatar from './avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from 'antd';
import {
  FileTextOutlined,
  PlaySquareOutlined,
  SwitcherOutlined,
  QuestionCircleOutlined,
  DeleteFilled,
  CloseCircleOutlined,
  PlusOutlined,
  EditOutlined,
  ReloadOutlined,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import {
  generateVideo,
  updateTopicContent,
  deleteScenes,
  createScenes,
  regenerateScene
} from '../../../pages/courseIndex/redux/topicContentSlice';
import axios from 'axios';
import toast from 'react-hot-toast';
import PreviewComponent from './previewComponent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { generatePptById } from '../../../pages/courses/redux/courseSlice';
import ImageFileWithType from './imageFileWithType';

const { TextArea } = Input;

const Generate = forwardRef((props, ref) => {
  console.log(props);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const localRef = useRef(null);
  const preiewRef = useRef(null);
  const leftColRef = useRef(null);
  let topicDetails = useSelector((state) => state.topic.topicData);
  const status = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  console.log(topicDetails, status, error);

  const { backgroundList } = useSelector((state) => state.lov);

  const [messageApi] = message.useMessage();

  const [selectedTopicData, setSelectedTopicData] = useState(null);
  const [scenesData, setScenesData] = useState([]);
  const [selectedScene, setSelectedScene] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedText, setSelectedText] = useState('');
  const [selectedMarkupText, setSelectedMarkupText] = useState('');
  const [selectedSceneIndex, setSelectedSceneIndex] = useState(null);
  const [selectedBackground, setSelectedBackground] = useState(
    'bg_1' || 'bg_1_9x16'
  );
  const [selectedAvatar, setSelectedAvatar] = useState('');
  const [selectedAnimation, setSelectedAnimation] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [selectedFileList, setSelectedFileList] = useState(null);
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [scale, setScale] = useState(1);
  const [isApplyAllDisabled, setIsApplyAllDisabled] = useState(false);
  const [negativeMargin, setNegativeMargin] = useState(0); // Adjust if needed
  const [isSceneModalOpen, setIsSceneModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteSelectedScene, setDeleteSelectedScene] = useState(null);
  const [sceneType, setSceneType] = useState(null);
  const [actionType, setActionType] = useState('');
  const [createNewSceneIndex, setCreateNewSceneIndex] = useState(null);
  const [aspectRatio, setAspectRatio] = useState('16:9');

  useEffect(() => {
    if (status === 'succeeded' && typeof topicDetails?.data !== 'string') {
      console.log(topicDetails.data);

      setSelectedTopicData(topicDetails.data);
      setAspectRatio(topicDetails?.data?.aspect_ratio);
      let scenesDataList = topicDetails.data.scenes;
      let sortedScenes = sortScenes(scenesDataList);
      console.log(sortedScenes);
      if (sortedScenes?.length > 0) {
        setScenesData(sortedScenes);
        setSelectedScene(sortedScenes[0]);
        setSelectedSceneIndex(sortedScenes[0].scene_index);
        setSelectedTitle(sortedScenes[0].scene_title);
        setSelectedText(removeSpace(sortedScenes[0].scene_narration));
        setSelectedMarkupText(removeSpace(sortedScenes[0].scene_text));
        setSelectedAudio(sortedScenes[0].scene_audio_template);
        setSelectedBackground(sortedScenes[0].scene_bg_template);
        setSelectedAnimation(sortedScenes[0].scene_style_template);
        setImageId(sortedScenes[0].scene_image);
        setImageType(sortedScenes[0].scene_image_type);
        setSelectedAvatar(sortedScenes[0].scene_avatar);
        setSceneType(sortedScenes[0].scene_type);
        setSelectedFileList([]);
      } else {
        setScenesData([]);
        setSelectedScene([]);
        setSelectedSceneIndex(null);
        setSelectedTitle('');
        setSelectedText('');
        setSelectedMarkupText('');
        setSelectedAudio('');
        if (topicDetails?.data?.aspect_ratio === '16:9') {
          setSelectedBackground('bg_1');
        } else {
          setSelectedBackground('bg_1_9x16');
        }
        setSelectedAnimation(null);
        setImageId(null);
        setImageType(null);
        setSelectedAvatar('');
        setSceneType(null);
      }
    }
  }, [status, topicDetails]);

  useImperativeHandle(ref, () => ({
    updateScene: () => {
      // Logic for updating the scene
      console.log('Scene updated from Generate component');
      handleUpdate();
    },
    generateVideoContent: () => {
      console.log('inside of the generating video content->>>>');
      handleGenerateVideo();
    },
    generatePptContent: () => {
      console.log('inside of the generating ppt content->>>>');
      handleDownloadPpt();
    }
  }));

  useEffect(() => {
    console.log(leftColRef);
    console.log(preiewRef);

    const updateScale = () => {
      if (leftColRef?.current && preiewRef?.current) {
        const colWidth = leftColRef.current.offsetWidth;
        const prevWidth = preiewRef.current.offsetWidth;
        const newWidth = colWidth / prevWidth;

        setScale(newWidth);
        // Optionally adjust margin to center the scaled content if necessary
        setNegativeMargin(0); // 50% for centering
      }
    };

    const checkAndSetScale = () => {
      if (leftColRef.current && preiewRef.current) {
        updateScale();
      } else {
        setTimeout(checkAndSetScale, 100); // Retry after a short delay if refs are not available
      }
    };

    checkAndSetScale(); // Initial call to set the scale
    window.addEventListener('resize', updateScale); // Adjust on resize

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, []);

  const handleMarkdownContent = (
    text,
    action,
    sceneType = '',
    language = 'python' ||
      'javascript' ||
      'prompt' ||
      'angular' ||
      'java' ||
      'ruby'
  ) => {
    if (text) {
      if (action === 'insert') {
        console.log(text);
        if (sceneType == 'CODE_SLIDE') {
          return `\`\`\`${language}\n${text}\n\`\`\``;
        } else {
          let textSentencesTrimmed = text.split('\n').map((s) => s.trim());
          return textSentencesTrimmed;
        }
      } else {
        return '';
      }
    } else {
      return text;
    }
  };
  //Apply All Function
  const handleApplyAll = () => {
    setIsApplyAllDisabled(false);
    const selectedBackground = scenesData[selectedSceneIndex].scene_bg_template;
    const selectedAvatar = scenesData[selectedSceneIndex].scene_avatar;

    // Check if all scenes already have the same background and avatar as the selected scene
    const isBackgroundChanged = scenesData.some(
      (scene) => scene.scene_bg_template !== selectedBackground
    );
    const isAvatarChanged = scenesData.some(
      (scene) => scene.scene_avatar !== selectedAvatar
    );

    const updatedScenes = scenesData.map((scene) => ({
      ...scene,
      scene_bg_template: selectedBackground,
      scene_avatar:
        selectedAvatar === 'none' || selectedAvatar === null
          ? null
          : selectedAvatar
    }));

    setScenesData(updatedScenes);

    // Display different messages based on what changed
    if (isBackgroundChanged && isAvatarChanged) {
      toast.success(
        'Selected Background and Avatar applied for all Scenes!',
        5
      );
    } else if (isAvatarChanged && selectedAvatar === 'none') {
      toast.success('Avatar removed for all Scenes!', 5);
    } else if (isAvatarChanged) {
      toast.success('Selected Avatar applied for all Scenes!', 5);
    } else if (isBackgroundChanged) {
      toast.success('Selected Background applied for all Scenes!', 5);
    }

    setIsApplyAllDisabled(true);
  };

  const handleBackgroundSelect = (image) => {
    setIsApplyAllDisabled(false);
    setSelectedBackground(image.image_name);
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id
        ? { ...s, scene_bg_template: image.image_name }
        : s
    );
    setScenesData(updatedScenes);
  };

  const handleAvatarSelect = (image) => {
    setIsApplyAllDisabled(false);
    console.log(image);
    if (image && image.image_name === 'none') {
      // Do nothing
      setSelectedAvatar(null);
    } else {
      setSelectedAvatar(image.image_name);
    }
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id
        ? { ...s, scene_avatar: image.image_name }
        : s
    );
    setScenesData(updatedScenes);
  };

  const handleAudioChange = (value) => {
    setIsApplyAllDisabled(false);
    setSelectedAudio(value);
    console.log(value);

    let scene = scenesData[selectedSceneIndex];

    // If "Apply All" is selected, update audio for all scenes
    if (isApplyAllDisabled) {
      const updatedScenes = scenesData.map((s) => ({
        ...s,
        scene_audio_template: value // Apply audio to all scenes
      }));
      setScenesData(updatedScenes);
    } else {
      // If not applying to all, update only the selected scene
      const updatedScenes = scenesData.map((s) =>
        s.scene_id === scene.scene_id
          ? { ...s, scene_audio_template: value }
          : s
      );
      setScenesData(updatedScenes);
    }
  };

  const onTextChange = (event) => {
    setSelectedText(event.target.value);
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id
        ? { ...s, scene_narration: event.target.value }
        : s
    );
    setScenesData(updatedScenes);
  };

  const handleFileChange = async ({ fileList }) => {
    // Log fileList to debug
    console.log('File List:', fileList);

    const updatedFileList = Array.isArray(fileList) ? fileList : [];
    const file = updatedFileList[0]?.originFileObj;

    if (file) {
      console.log('File Details:', file);

      const newFileName = selectedScene.scene_id;
      const renamedFile = new File([file], newFileName, { type: file.type });
      console.log('File Type ===> ', file.type);

      let fileType = 'IMG';
      if (file.type == 'video/mp4') {
        fileType = 'MP4';
      }
      let updatedSceneType = ' ';
      if (sceneType === 'CONTENT_SLIDE') {
        updatedSceneType = 'CONTENT_SLIDE_WITH_IMAGE';
        setSceneType('CONTENT_SLIDE_WITH_IMAGE');
        if (imageId != null) {
          updatedSceneType = 'CONTENT_SLIDE_WITH_IMAGE';
          setSceneType('CONTENT_SLIDE_WITH_IMAGE');
        }
      } else {
        updatedSceneType = sceneType;
      }
      let scene = scenesData[selectedSceneIndex];
      const updatedScenes = scenesData.map((s) =>
        s.scene_id === scene.scene_id
          ? {
              ...s,
              scene_image: newFileName,
              scene_image_type: fileType,
              scene_type: updatedSceneType
            }
          : s
      );
      setScenesData(updatedScenes);

      const formData = new FormData();
      Object.keys(selectedScene.upload_url.fields).forEach((key) => {
        formData.append(key, selectedScene.upload_url.fields[key]);
      });
      formData.append('file', renamedFile);

      try {
        const response = await axios.post(
          selectedScene.upload_url.url,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        if (response.status === 204) {
          // setSelectedFileList([]);
          setImageId(newFileName);
          setImageType(fileType);
        } else {
          toast.error('File upload failed');
          setSceneType('CONTENT_SLIDE');
        }
      } catch (error) {
        toast.error('File upload error: ' + error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setSelectedFileList((prevState) =>
        prevState.map((item) =>
          item.hasOwnProperty(selectedScene.scene_id)
            ? { [selectedScene.scene_id]: null }
            : item
        )
      );
    }
  };

  const handleImageRemove = async () => {
    console.log('File deleted successfully');
    handleMarkdownContent(selectedMarkupText, 'delete');
    // Update scenesData to remove the image
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id ? { ...s, scene_image: '' } : s
    );
    setScenesData(updatedScenes);

    // Reset the image ID and file list
    setImageId(null);
    setImageType(null);
    setSelectedFileList((prevState) =>
      prevState.map((item) =>
        item.hasOwnProperty(selectedScene.scene_id)
          ? { [selectedScene.scene_id]: null }
          : item
      )
    );
    setSceneType('CONTENT_SLIDE');
    toast.success('Image Deleted Successfully');
  };

  const onMarkupTextChange = (event) => {
    setSelectedMarkupText(event.target.value);
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id
        ? { ...s, scene_text: event.target.value }
        : s
    );
    setScenesData(updatedScenes);
  };

  const handleSceneTitleChange = (event) => {
    console.log(event);
    setSelectedTitle(event.target.value);
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id
        ? { ...s, scene_title: event.target.value }
        : s
    );
    setScenesData(updatedScenes);
  };

  const handleSceneTypeChange = (event) => {
    console.log(event);

    setSceneType(event);
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id ? { ...s, scene_type: event } : s
    );
    console.log(updatedScenes);

    setScenesData(updatedScenes);
  };

  const onFinishRegenerateScene = async (values) => {
    let ai_generated_value = 'true';
    console.log('Received values of form:', values);
    console.log(scenesData);
    console.log(selectedTopicData);
    setLoading(true);
    try {
      console.log(selectedTopicData?.scenes.length);
      const response = await dispatch(
        regenerateScene({
          video_id: selectedTopicData?.video_id,
          scene_id: selectedScene?.scene_id,
          topic: selectedScene?.scene_title,
          scene_index: selectedSceneIndex,
          scene_type: sceneType,
          ai_generate: ai_generated_value,
          scene_bg_template: selectedScene.scene_bg_template,
          scene_avatar: selectedScene.scene_avatar
        })
      );
      // Handle the response here
      console.log('Scene regenerated successfully:', response);
      let newScene = response.payload.data;
      console.log('Regenerated scene => ', newScene);
      newScene = {
        ...newScene,
        scene_bg_template: selectedScene.scene_bg_template,
        scene_avatar: selectedScene.scene_avatar
      };
      const updatedScenes = scenesData.map((scene) =>
        scene.scene_id === selectedScene?.scene_id ? newScene : scene
      );

      // Update your scenes data state with the new list
      setScenesData(updatedScenes);

      // Optionally, re-select the scene to update UI with regenerated data
      setSelectedScene(newScene);
      setSelectedSceneIndex(newScene.scene_index);
      setSelectedTitle(newScene.scene_title);
      setSelectedText(newScene.scene_narration);
      setSelectedMarkupText(newScene.scene_text);
      setSelectedAudio(newScene.scene_audio_template);
      setSelectedBackground(newScene.scene_bg_template);
      setSelectedAnimation(newScene.scene_style_template);
      setImageId(newScene.scene_image);
      setImageType(newScene.scene_image_type);
      setSelectedAvatar(newScene.scene_avatar);
      setSceneType(newScene.scene_type);
      setSelectedFileList([]);
      console.log('selected scene => ', selectedScene);

      // Perform any additional UI updates or show success messages
      setLoading(false);
    } catch (error) {
      // Handle any errors
      console.error('Error regenerating scene:', error);
      // Optionally, show error messages to the user
      setLoading(false);
    }
  };

  const itemsTextComp = [
    {
      key: '1',
      label: 'Content Text',
      children: (
        <ContentText
          selectedBackground={selectedBackground}
          onBackgroundSelect={handleBackgroundSelect}
          selectedMarkupText={selectedMarkupText}
          onMarkupTextChange={onMarkupTextChange}
          handleSceneTitleChange={handleSceneTitleChange}
          selectedTitle={selectedTitle}
          sceneType={sceneType}
          handleSceneTypeChange={handleSceneTypeChange}
          onFinishRegenerateScene={onFinishRegenerateScene}
        />
      )
    },
    {
      key: '2',
      label: 'Audio Text',
      children: (
        <AudioText selectedText={selectedText} onTextChange={onTextChange} />
      )
    }
  ];

  const items = [
    {
      key: '1',
      label: 'Background',
      children: (
        <Background
          selectedBackground={selectedBackground}
          onBackgroundSelect={handleBackgroundSelect}
          aspectRatio={aspectRatio}
          selectedTopicData={selectedTopicData}
          selectedScene={selectedScene}
        />
      )
    },
    {
      key: '2',
      label: 'Avatar',
      children: (
        <Avatar
          selectedAvatar={selectedAvatar}
          onAvatarSelect={handleAvatarSelect}
        />
      )
    },
    {
      key: '3',
      label: 'Audio',
      children: (
        <Audio
          onAudioSelect={handleAudioChange}
          selectedAudio={selectedAudio}
          selectedText={selectedText}
          onTextChange={onTextChange}
        />
      )
    },
    {
      key: '4',
      label: 'Media',
      disabled: sceneType === 'CODE_SLIDE' || sceneType === 'TITLE_SLIDE',
      children: (
        <Images
          onFileSelect={handleFileChange}
          onFileRemove={handleImageRemove}
          fileList={selectedFileList}
          currentSceneImageName={imageId}
        />
      )
    }
  ];

  const handleCardClick = (scene) => {
    setSelectedScene(scene);
    setSelectedSceneIndex(scene.scene_index);
    setSelectedTitle(scene.scene_title);
    setSelectedText(scene.scene_narration);
    setSelectedMarkupText(scene.scene_text);
    setSelectedAudio(scene.scene_audio_template);
    setSelectedBackground(scene.scene_bg_template);
    setSelectedAnimation(scene.scene_style_template);
    setSceneType(scene.scene_type);
    setSelectedAvatar(scene.scene_avatar);

    setImageId(scene.scene_image);
    setImageType(scene.scene_image_type);
    setSelectedFileList([]);
  };

  const removeSpace = (text) => {
    if (text) {
      return text.replace(/\s+/g, ' ');
    } else {
      return '';
    }
  };

  const handleUpdate = async () => {
    let updSceneData = { scenes: scenesData }; //[...filterData, updatedSceneData] };
    let updatedTopicData = Object.assign({}, selectedTopicData, updSceneData);
    const asyncAction = new Promise((resolve, reject) => {
      dispatch(updateTopicContent(updatedTopicData));
      resolve();
    });
    asyncAction
      .then(() => {
        setSelectedTopicData(updatedTopicData);
        toast.success(<>Scenes getting updated successfully</>);
      })
      .catch((error) => {
        // Handle errors if necessary
        console.error('Error:', error);
        toast.error(
          <>
            Oh Sorry you cannot able to update the template, please try again.
          </>,
          {
            style: { fontSize: '15px', autoClose: 5000, marginRight: '150px' } // You can still adjust the font size if needed
          }
        );
      })
      .finally(() => {
        // Reset loading state regardless of success or failure
      });
  };
  const handleGenerateVideo = (e) => {
    const asyncAction = new Promise((resolve, reject) => {
      dispatch(generateVideo({ video_id: topicDetails?.data?.video_id }));

      resolve();
    });

    asyncAction
      .then(() => {
        // toast.success(
        //   'Generating video is inprogress. please watch the video after few minutes later',
        //   5.0
        // );
        toast.success(
          <>
            Generating video is inprogress. please, <br />
            watch the video after few minutes later
          </>
        );
        if (props.handleCancel) {
          props.handleCancel(); // Close the modal after generating the video
        }
      })
      .catch((error) => {
        // Handle errors if necessary
        console.error('Error:', error);
        messageApi.error(
          'Oh Sorry video is not getting generated, please try again.',
          2.5
        );
      })
      .finally(() => {
        // Reset loading state regardless of success or failure
      });
  };

  const handleDownloadPpt = async (e) => {
    // Generate ppt content based on the course data
    // e.preventDefault();

    // Show a loading toast while the downloading is in progress
    const loadingToastId = toast.loading('Downloading Powerpoint...');

    // Dispatch deleteVideoById and wait for it to complete
    const resultAction = await dispatch(
      generatePptById({
        video_id: topicDetails?.data?.video_id
      })
    );
    console.log(resultAction);

    // Check if the generatePptById was successful
    if (generatePptById.fulfilled.match(resultAction)) {
      // Extract the data from the resultAction
      const { data } = resultAction.payload;
      if (data?.url) {
        // Redirect to the download URL
        window.location.href = data.url;
        // Update the toast to a success message
        toast.success('PowerPoint Downloaded successfully!', {
          id: loadingToastId
        });
      } else {
        throw new Error('Failed to get download URL');
      }
    } else {
      // If the generate ppt failed, update the loading toast to an error message
      toast.error(
        `Failed to download PowerPoint: ${
          resultAction.payload?.message || resultAction.error.message
        }`,
        { id: loadingToastId }
      );
    }
  };

  const selectedImage = (scene) => {
    setSelectedBackgroundImage(
      require(`../../../asset/images/background/${scene.scene_bg_template}.gif`)
    );
  };

  const helpContent = (
    <div>
      <p>
        Hey there! Feel free to <strong>edit</strong> and <strong>view </strong>
        your
      </p>
      <p>text based on your needs.</p>
    </div>
  );

  const helpSceneContent = (
    <div>
      <p>
        You can select individual scenes for <strong>edit</strong> and{' '}
        <strong>preview</strong>.{' '}
      </p>
      <p>Customize each scene to meet your needs.</p>
    </div>
  );

  const deleteScene = async (event, sceneId) => {
    event.stopPropagation();
    setConfirmLoading(true);

    try {
      await dispatch(
        deleteScenes({
          video_id: selectedTopicData?.video_id,
          scene_id: sceneId
        })
      );

      let removeScene = scenesData.filter(
        (scene) => scene.scene_id !== sceneId
      );
      let reindexedScenes = removeScene.map((scene, index) => ({
        ...scene,
        scene_index: index
      }));

      console.log(reindexedScenes);
      setConfirmLoading(false);
      setDeleteSelectedScene(null);

      if (reindexedScenes.length > 0) {
        let sortedScenes = sortScenes(reindexedScenes);
        setScenesData(sortedScenes);
        setSelectedScene(sortedScenes[0]);
        setSelectedSceneIndex(sortedScenes[0].scene_index);
        setSelectedTitle(sortedScenes[0].scene_title);
        setSelectedText(removeSpace(sortedScenes[0].scene_narration));
        setSelectedMarkupText(removeSpace(sortedScenes[0].scene_text));
        setSelectedAudio(sortedScenes[0].scene_audio_template);
        setSelectedBackground(sortedScenes[0].scene_bg_template);
        setSelectedAnimation(sortedScenes[0].scene_style_template);
        setImageId(sortedScenes[0].scene_image);
        setImageType(sortedScenes[0].scene_image_type);
        setSceneType(sortedScenes[0].scene_type);
        setSelectedAvatar(sortedScenes[0].scene_avatar);
        if (sortedScenes[0].scene_image === '') {
          setSelectedFileList([]);
        } else {
          if (sceneType == 'CONTENT_SLIDE')
            setSceneType('CONTENT_SLIDE_WITH_IMAGE');
        }

        toast.success('Scene deleted successfully!');
      } else {
        setScenesData([]);
        setSelectedScene(null); // Avoid setting it to an empty array, use null
        setSelectedSceneIndex(null);
        setSelectedTitle('');
        setSelectedText('');
        setSelectedMarkupText('');
        setSelectedAudio('');
        if (aspectRatio === '16:9') {
          setSelectedBackground('bg_1');
        } else {
          setSelectedBackground('bg_1_9x16');
        }
        setSelectedAnimation(null);
        setImageId(null);
        setImageType(null);
        setSelectedAvatar('');
        setSceneType(null);

        toast.info('All scenes have been deleted.');
      }
    } catch (error) {
      console.error('Error deleting scene:', error);

      // Show error message
      toast.error('Failed to delete the scene. Please try again.');
    } finally {
      setConfirmLoading(false);
      setDeleteSelectedScene(null); // Clear selection after deletion attempt
    }
  };

  const sortScenes = (unsortedData) => {
    return unsortedData?.slice().sort((a, b) => a.scene_index - b.scene_index);
  };

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (destination.index === source.index) return; // Item dropped in the same place

    const reorderedScenes = Array.from(scenesData);
    const [movedScene] = reorderedScenes.splice(source.index, 1);
    reorderedScenes.splice(destination.index, 0, movedScene);

    setScenesData(reorderedScenes);
  };

  const handleCancel = () => {
    setIsSceneModalOpen(false);
  };

  const showSceneModal = (index) => {
    setIsSceneModalOpen(true);
    setCreateNewSceneIndex(++index);
  };

  const onFinishScene = async (values) => {
    let ai_generated_value = 'false';
    if (actionType === 'GenerateByAI') {
      ai_generated_value = 'true';
    }

    console.log('Received values of form:', values);
    console.log(scenesData);
    console.log(selectedTopicData);
    console.log(selectedScene);
    console.log(createNewSceneIndex);

    setLoading(true);
    try {
      console.log(selectedTopicData?.scenes.length);
      const response = await dispatch(
        createScenes({
          video_id: selectedTopicData?.video_id,
          topic: values.sceneName,
          scene_type: values.sceneType,
          scene_index: createNewSceneIndex, //selectedTopicData?.scenes.length,
          ai_generate: ai_generated_value,
          scene_bg_template: selectedScene.scene_bg_template,
          scene_avatar: selectedScene.scene_avatar
        })
      );
      // Handle the response here
      console.log('Scene created successfully:', response);
      let newScene = response.payload.data;
      console.log(newScene);

      newScene = {
        ...newScene,
        scene_index: createNewSceneIndex, //selectedTopicData?.scenes.length
        scene_bg_template: selectedScene.scene_bg_template,
        scene_avatar: selectedScene.scene_avatar
      };
      console.log(newScene);
      console.log(scenesData.length);
      console.log(scenesData);
      const updatedScenes = updateSceneIndices(scenesData, newScene);
      console.log(updatedScenes.length);
      console.log(updatedScenes);

      if (
        response.payload.data ===
          'Unable to generate scene at this point. Try after sometime!' ||
        response.payload.data ===
          'Unable to generate scene at this point. Try after sometime!'
      ) {
        toast.error(
          response.payload.data +
            '. ' +
            'If the problem persists, contact support at info@techjedi.in.'
        );
      } else {
        console.log(updatedScenes);
        let sortedScenes = sortScenes(updatedScenes);
        console.log(sortedScenes);
        setScenesData(sortedScenes);
        let createdData = sortedScenes.filter(
          (scene) => scene?.scene_id === response?.payload?.data?.scene_id
        );
        console.log('created data' + createdData);

        setSelectedScene(createdData[0]);
        setSelectedSceneIndex(newScene.scene_index);
        setSelectedTitle(newScene.scene_title);
        setSelectedText(newScene.scene_narration);
        setSelectedMarkupText(newScene.scene_text);
        setSelectedAudio(newScene.scene_audio_template);
        setSelectedBackground(newScene.scene_bg_template);
        setSelectedAnimation(newScene.scene_style_template);
        setImageId(newScene.scene_image);
        setImageType(newScene.scene_image_type);
        setSelectedAvatar(newScene.scene_avatar);
        setSceneType(newScene.scene_type);
        if (newScene.scene_image === '') {
          setSelectedFileList([]);
        } else {
          // Incase of content slide - make it a 'CONTENT_SLIDE_WITH_IMAGE'
          if (sceneType == 'CONTENT_SLIDE')
            setSceneType('CONTENT_SLIDE_WITH_IMAGE');
        }

        // Reset form fields after successful creation
        form.resetFields();

        // Do additional processing here, e.g., show a success message, update local state, etc.
        setLoading(false);
        setIsSceneModalOpen(false);

        toast.success('Scene created successfully!');
      }
    } catch (error) {
      // Handle errors
      console.error('Error creating scene:', error);
      toast.error('Failed to create scene. Please try again later.');
      setLoading(false);
    }
  };

  const updateSceneIndices = (scenes, newScene) => {
    let sceneIndex = newScene.scene_index;
    return scenes.reduce(
      (updatedScenes, scene) => {
        if (scene.scene_index >= newScene.scene_index) {
          sceneIndex = sceneIndex + 1;
          // Create a new object with the updated index
          const updatedScene = { ...scene, scene_index: sceneIndex };
          updatedScenes.push(updatedScene);
        } else {
          updatedScenes.push(scene);
        }
        return updatedScenes;
      },
      [newScene]
    ); // Start with the new scene added
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (sceneId) => {
    setOpen(true);
    setDeleteSelectedScene(sceneId);
  };

  const handleDeleteCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
    setDeleteSelectedScene(null);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  // Define the scene type options
  const options = [
    { value: 'CODE_SLIDE', label: 'Code' },
    { value: 'CONTENT_SLIDE', label: 'Content' },
    { value: 'CONTENT_SLIDE_WITH_IMAGE', label: 'Content with Image' },
    { value: 'TITLE_SLIDE', label: 'Title' },
    { value: 'IMAGE_ONLY_SLIDE', label: 'Image' }
  ];

  return (
    <div className={styles.wrapper} ref={localRef}>
      {loading && (
        <div
          style={{
            position: 'fixed', // Use 'fixed' to keep it relative to the viewport
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center', // Center vertically
            justifyContent: 'center', // Center horizontally
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: semi-transparent background
            zIndex: 9999,
            pointerEvents: 'none'
          }}
        >
          <Spin size="large" tip="Uploading..." />
        </div>
      )}
      {status === 'succeeded' ? (
        <>
          <div ref={leftColRef} className={styles.contnetSection}>
            <div className={styles.slides}>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <SwitcherOutlined
                  style={{ marginRight: '8px', marginLeft: '10px' }}
                />
                <VaTitle level={5} text="Scenes" />
                <Popover content={helpSceneContent} title="Help">
                  <QuestionCircleOutlined
                    style={{
                      marginLeft: '8px',
                      fontSize: '12px',
                      cursor: 'pointer'
                    }}
                  />
                </Popover>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  type="text"
                  icon={<LeftOutlined />}
                  onClick={() => {
                    if (selectedSceneIndex > 0) {
                      setSelectedSceneIndex(selectedSceneIndex - 1);
                      handleCardClick(scenesData[selectedSceneIndex - 1]);
                    }
                  }}
                  disabled={selectedSceneIndex === 0} // Disable if it's the first scene
                  style={{ marginTop: '40px', marginRight: '5px' }}
                />
                <div className={styles.scenesList}>
                  {scenesData.map((item, index) => (
                    <div
                      className={styles.sceneWrapper}
                      onClick={() => handleCardClick(item)}
                    >
                      <div className={styles.sceneText}>
                        <VaText text={`Scene ${index + 1}`} />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div
                          className={`${styles.scenes} ${
                            selectedScene &&
                            selectedScene.scene_id === item.scene_id
                              ? styles.active
                              : ''
                          }`}
                        >
                          {selectedScene &&
                            selectedScene.scene_id === item.scene_id &&
                            scenesData.length > 1 && (
                              <div className={styles.closeIconWrapper}>
                                <Popconfirm
                                  title="Delete Scene"
                                  description={`Do you want to delete the Scene: ${
                                    item.scene_title || 'No title available'
                                  }.`}
                                  open={deleteSelectedScene === item.scene_id}
                                  onConfirm={(event) =>
                                    deleteScene(event, item?.scene_id)
                                  }
                                  okButtonProps={{
                                    loading: confirmLoading
                                  }}
                                  onCancel={handleDeleteCancel}
                                >
                                  <Button
                                    type="text"
                                    icon={<DeleteFilled />}
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevents the scene from being selected again when clicking the button
                                      showPopconfirm(item?.scene_id);
                                    }}
                                    className={styles.closeButton}
                                  />
                                </Popconfirm>
                              </div>
                            )}
                          <div className={styles.imageContainer}>
                            <Image
                              preview={false}
                              // src={require(`../../../asset/images/background/${item.scene_bg_template}.gif`)}
                              // src={`https://va-background-images.s3.amazonaws.com/${item?.scene_bg_template}.gif`}
                              src={ImageFileWithType(
                                backgroundList,
                                item?.scene_bg_template,
                                item?.image_type || ''
                              )}
                            />
                            <div className={styles.centeredText}>
                              {item.scene_title}
                            </div>
                          </div>
                        </div>
                        <div className={styles.sceneAdd}>
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={() => showSceneModal(index)}
                            className={styles.addNewSceneButton}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <Button
                  type="text"
                  icon={<RightOutlined />}
                  onClick={() => {
                    if (selectedSceneIndex < scenesData.length - 1) {
                      setSelectedSceneIndex(selectedSceneIndex + 1);
                      handleCardClick(scenesData[selectedSceneIndex + 1]);
                    }
                  }}
                  disabled={selectedSceneIndex === scenesData.length - 1} // Disable if it's the last scene
                  style={{ marginTop: '40px', marginLeft: '5px' }}
                />
              </div>
            </div>
            <div className={styles.preview}>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <PlaySquareOutlined
                  style={{ marginRight: '8px', marginLeft: '10px' }}
                />
                <VaTitle
                  level={5}
                  text={'Scene ' + (selectedSceneIndex + 1) + ' - Preview'}
                />
              </div>
              <div
                ref={preiewRef}
                style={{
                  // width: '1980px',
                  // height: '1080px',
                  // maxHeight: '60rem',
                  transform: `scale(${scale})`,
                  transformOrigin: 'top left', // Adjust the origin as needed
                  backgroundColor: '#f0f0f0', // Example background
                  // overflow: 'hidden', // Prevent overflow
                  marginLeft: `-${negativeMargin}%`,
                  marginTop: `-${negativeMargin}%`
                }}
              >
                {/* {scenesData.map((itemData, i) => ( */}
                <div className={styles.remotionPlayer}>
                  <PreviewComponent
                    sceneType={sceneType}
                    selectedTitle={selectedTitle}
                    aspectRatio={aspectRatio}
                    selectedContent={handleMarkdownContent(
                      selectedMarkupText,
                      'insert',
                      sceneType
                    )}
                    // selectedBackground={require(`../../../asset/images/background/${selectedBackground}.gif`)}
                    // selectedBackground={`https://va-background-images.s3.amazonaws.com/${selectedBackground}.gif`}
                    selectedBackground={ImageFileWithType(
                      backgroundList,
                      selectedBackground,
                      ''
                    )}
                    selectedAnimation={selectedAnimation || 'fadeIn'}
                    imageId={imageId}
                    imageType={imageType}
                  />
                  {selectedAvatar !== null && selectedAvatar !== '' ? (
                    <div className={styles.avatar}>
                      <img
                        src={require(`../../../asset/images/avatar/${selectedAvatar}.jpg`)}
                        alt="Avatar"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.contnetActions}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
              <div className={styles.tabs}>
                <Tabs defaultActiveKey="1" items={itemsTextComp} />
              </div>
            </div>

            <div className={styles.textArea}></div>
            <div className={styles.tabs}>
              <Tabs defaultActiveKey="1" items={items} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                variant="primary"
                onClick={handleApplyAll}
                disabled={isApplyAllDisabled} // This controls the button's disabled state
                style={{
                  backgroundColor: isApplyAllDisabled ? '#ccc' : 'darkorange', // Optional: Change color when disabled
                  color: 'white',
                  paddingLeft: '25px',
                  paddingRight: '25px',
                  cursor: isApplyAllDisabled ? 'not-allowed' : 'pointer' // Change cursor when disabled
                }}
              >
                Apply All
              </Button>
              <p style={{ marginLeft: '10px' }}>
                <strong>Note: </strong> Apply all only for Background and Avatar
              </p>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            textAlign: 'center',
            position: 'relative',
            height: '600px',
            left: '260px'
          }}
        >
          <Spin
            size="large"
            style={{ alignContent: 'center', marginTop: '250px' }}
            className="spinner-container"
          />
          <div style={{ marginTop: '20px', fontSize: '16px', color: '#000' }}>
            Just a sec! It’s almost ready for you!
          </div>
        </div>
      )}
      <Modal
        title="Create New Scene"
        open={isSceneModalOpen}
        onCancel={() => {
          handleCancel();
          form.resetFields(); // Reset the input fields when modal is closed
        }}
        maskClosable={true} // Prevents closing on click outside
        footer={[
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '16px',
              marginTop: '20px'
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setActionType('GenerateByAI');
                form.submit();
              }}
            >
              AI Generate
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setActionType('CustomGenerate');
                form.submit();
              }}
            >
              Custom Generate
            </Button>
          </div>
        ]}
      >
        <div className={styles.formWrapper}>
          <Form
            name="createScene"
            form={form}
            onFinish={onFinishScene}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Enter your Scene Title:"
              name="sceneName"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Scene Name!'
                }
              ]}
            >
              <Input placeholder="Eg: Learn ChatGPT" />
            </Form.Item>
            <Form.Item
              label="Enter your Scene Type:"
              name="sceneType"
              rules={[
                {
                  required: true,
                  message: 'Please select your Scene Type!'
                }
              ]}
            >
              <Select
                // mode="multiple"
                style={{
                  width: '100%'
                }}
                placeholder="Select one scene type"
                // defaultValue={['china']}
                onChange={handleChange}
                options={options}
              />
            </Form.Item>
          </Form>
        </div>
        {loading && (
          <div className={styles.loaderContainer}>
            <Spin
              size="large"
              // style={{ display: 'block', margin: '0 auto', marginTop: '200px' }}
            />
            <p>Topic is Generating...</p>
          </div>
        )}
      </Modal>
    </div>
  );
});

export default Generate;
