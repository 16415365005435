import React, { useEffect, useState } from 'react';
import styles from './generate.module.scss';
import { Image, Upload, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { InboxOutlined } from '@ant-design/icons';
import toast from 'react-hot-toast';
import { getBgImagesList, addNewCustomBg } from 'pages/courses/redux/lovSlice';
import axios from 'axios';
import ImageFileWithType from './imageFileWithType';
const { Dragger } = Upload;

export default function Background({
  selectedBackground,
  onBackgroundSelect,
  aspectRatio,
  selectedTopicData,
  selectedScene
}) {
  console.log(selectedTopicData);

  const dispatch = useDispatch();
  const [currentAspectRatio, setCurrentAspectRatio] = useState(aspectRatio);
  const [loading, setLoading] = useState(false);
  const { status, backgroundList, error, isNewBgAdded, newBgStatus } =
    useSelector((state) => state.lov);
  console.log(status, backgroundList, error);
  console.log(backgroundList);

  const props = {
    name: 'file',
    multiple: false,
    showUploadList: false, // Hide progress bar and file list
    customRequest: async ({ file, onSuccess, onError }) => {
      setLoading(true);
      try {
        // Extract the details from bg_background_url
        const url = selectedTopicData.bg_upload_url.url;
        const fields = selectedTopicData.bg_upload_url.fields;

        // Create a FormData object
        const formData = new FormData();
        Object.keys(fields).forEach((key) => {
          formData.append(key, fields[key]);
        });
        Object.keys(selectedTopicData.bg_upload_url.fields).forEach((key) => {
          formData.append(key, selectedTopicData.bg_upload_url.fields[key]);
        });
        console.log(selectedScene);
        const fileExtension = file.name.split('.').pop();
        const newFileName = selectedTopicData.video_id + '_bg'; // + '.' + fileExtension;
        console.log(newFileName);
        const renamedFile = new File(
          [file],
          newFileName, // + '.' + fileExtension,
          { type: file.type }
        );
        console.log(renamedFile);

        // Append the file to the form data
        formData.append('file', renamedFile);

        // Make a POST request to the S3 URL
        const imgResponse = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        console.log(imgResponse);

        // If successful
        onSuccess('Ok');
        const response = await dispatch(
          addNewCustomBg({
            background_image: newFileName,
            resolution: currentAspectRatio,
            image_type: fileExtension
          })
        );
        console.log(response, newBgStatus, isNewBgAdded);

        if (newBgStatus === 'succedded' && isNewBgAdded?.data) {
          dispatch(getBgImagesList());
          toast.success('Background file uploaded successfully.');
        }
      } catch (err) {
        // Handle the error
        console.error(err);
        onError(err);
        toast.error(`${file.name} file upload failed.`);
      } finally {
        setLoading(false);
      }
    },
    onChange(info) {
      const { status } = info.file;
      console.log(status);

      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    }
  };

  const imageData =
    currentAspectRatio === '16:9'
      ? backgroundList?.data['16:9']
      : backgroundList?.data['9:16'];
  const customImageData =
    currentAspectRatio === '16:9'
      ? backgroundList?.data['custom_16:9']
      : backgroundList?.data['custom_9:16'];
  const imageList = [...imageData, ...customImageData];

  useEffect(() => {
    setCurrentAspectRatio(aspectRatio);
  }, [aspectRatio]);
  console.log(imageList);

  return (
    <div className={styles.backgroundContainer}>
      <div className={styles.backgroundWrapper}>
        {imageList.map((item) => (
          <div
            key={item?.id}
            className={`${styles.bgCard} ${
              selectedBackground === item?.image_name ? styles.selected : ''
            }`}
            onClick={() => onBackgroundSelect(item)}
          >
            <Image
              preview={false}
              // src={require(`../../../asset/images/background/${item.image_name}.gif`)}
              // src={`https://va-background-images.s3.amazonaws.com/${item?.image_name}.gif`}
              src={ImageFileWithType(
                backgroundList,
                item?.image_name,
                item?.image_type || ''
              )}
            />
          </div>
        ))}
      </div>
      <div style={{ marginTop: '1rem' }}>
        {loading && (
          <div className={styles.loaderContainer}>
            <Spin
              size="large"
              // style={{ display: 'block', margin: '0 auto', marginTop: '200px' }}
            />
            <p>Background image is uploading...</p>
          </div>
        )}
        <Dragger {...props}>
          <p>
            <InboxOutlined />
          </p>
          <p>Click or drag file to this area to upload</p>
          <p>
            Support for a single or bulk upload. Strictly prohibited from
            uploading company data or other banned files.
          </p>
        </Dragger>
      </div>
    </div>
  );
}
