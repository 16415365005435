import { Player, Thumbnail } from '@remotion/player';
import { sceneTypeToComponent16x9 } from './16x9/sceneTypeToComponent16x9';
import { sceneTypeToComponent9x16 } from './9x16/sceneTypeToComponent9x16';

export default function PreviewComponent({
  aspectRatio,
  sceneType,
  selectedTitle,
  selectedContent,
  selectedBackground,
  selectedAnimation,
  imageId,
  imageType
}) {
  const calculate9x16PreviewStyle = () => {
    const maxWidth = window.innerWidth < 1024 ? '100%' : '400px'; // Adjust for smaller screens
    const height = (1280 / 720) * parseInt(maxWidth, 10); // Maintain aspect ratio
    return {
      width: maxWidth,
      height: `${height}px`,
      maxHeight: 'calc(100vh - 100px)', // Keep some padding from the viewport edge
      overflow: 'hidden'
    };
  };
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    height: '100%', // Fill the container height
    width: '100%' // Fill the container width
  };
  if (aspectRatio == '16:9') {
    return (
      <Thumbnail
        component={sceneTypeToComponent16x9[sceneType]}
        compositionWidth={1280}
        compositionHeight={720}
        frameToDisplay={1000}
        durationInFrames={1200}
        fps={30}
        style={{
          width: '100%'
        }}
        inputProps={{
          title: selectedTitle,
          content: selectedContent,
          background: selectedBackground,
          animationStyle: selectedAnimation,
          imageId: imageId,
          imageType: imageType
        }}
      />
    );
  } else {
    return (
      <div style={containerStyle}>
        <Thumbnail
          component={sceneTypeToComponent9x16[sceneType]}
          compositionWidth={720}
          compositionHeight={1280}
          frameToDisplay={1000}
          durationInFrames={1200}
          fps={30}
          style={calculate9x16PreviewStyle()} // Apply dynamic style for 9x16 aspect ratio
          // style={{
          //   width: '100%'
          // }}
          inputProps={{
            title: selectedTitle,
            content: selectedContent,
            background: selectedBackground,
            animationStyle: selectedAnimation,
            imageId: imageId,
            imageType: imageType
          }}
        />
      </div>
    );
  }
}
